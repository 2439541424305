:root {
    --page-background-color: #FFFFFF;
    --text-color: #1F1F27;
    --link-padding-sides: 16px;
    --link-padding-top-bottom: 8px;
    --link-margin-sides: 16px;
    --link-margin-top-bottom: 8px;
}

body {
    font-family: 'DM Sans';
    font-weight: 400;
    font-display: swap;
    color: var(--text-color);
}

h2 {
    color: var(--text-color);
}

.MuiTypography-root {
    font-family: 'DM Sans' !important;
    letter-spacing: -0.01562em !important;
}

.headerxl {
    font-weight: 400 !important;
    font-size: 7.563rem !important;
    line-height: 1 !important;
}

.header-large {
    font-weight: 500 !important;
    font-size: 5.063rem !important;
    line-height: 1 !important;
}

.header-1-2 {
    font-weight: 600 !important;
    font-size: 3.375rem !important;
    line-height: 1.2 !important;
}

.header-3 {
    font-weight: 600 !important;
    font-size: 2.25rem !important;
    line-height: 1.2 !important;
}

.header-4 {
    font-weight: 300 !important;
    font-size: 1.5rem !important;
    line-height: 1.2 !important;
}

.logo-text {
    font-weight: 600 !important;
    font-size: 1.7rem !important;
    line-height: 1.2 !important;
}

.body-text {
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
}

p {
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
}

.secondary {
    font-weight: 400 !important;
    font-size: 10.67px !important;
    line-height: 1.5 !important;
}

a:visited {
    color: var(--text-color);
}

a {
    color: var(--text-color);
}

.header-link {
    text-decoration: none;
    font-weight: 600;
    padding: 8px 16px 8px 16px;
}

.active {
    font-weight: 600;
    padding: 8px 16px 8px 16px;
    text-decoration: underline;
    text-underline-offset: 8px;
}

.header-link:hover {
    font-weight: 600;
    padding: 8px 16px 8px 16px;
    text-decoration: underline;
    text-underline-offset: 8px;
}

.header-logo-img {
    max-width: 128px;
}

.footer {
    color: var(--page-background-color);
    background-color: black;
}

.footer-link {
    text-decoration: none;
    font-weight: 500;
    padding: 4px 8px 4px 8px;
    color: var(--page-background-color) !important;
}

.footer-link:hover {
    text-decoration: none;
    font-weight: 500;
    padding: 4px 8px 4px 8px;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.footer-social-img {
    max-height: 24px;
}

.footer .MuiSvgIcon-root {
    font-size: 2rem !important;
}

.button-outline {
    border: solid 2px black;
    text-decoration: none;
    padding: 8px 32px 8px 32px;
    border-radius: 32px;
    min-width: 100px;
    text-align: center;
}

.button-outline:hover {
    border: solid 2px black;
    text-decoration: none;
    padding: 8px 32px 8px 32px;
    border-radius: 32px;
    min-width: 100px;
    background-color: black;
    color: white;
}

.button-primary {
    border: solid 2px black;
    text-decoration: none;
    padding: 8px 32px 8px 32px;
    border-radius: 32px;
    min-width: 100px;
    background-color: black;
    color: white !important;
    text-align: center;
}

.button-primary:hover {
    border: solid 2px black;
    text-decoration: none;
    padding: 8px 32px 8px 32px;
    border-radius: 32px;
    min-width: 100px;
    text-align: center;
    background-color: white !important;
    color: black !important;
}

.category-link {
    text-decoration: none;
}

.category-link:hover {
    text-decoration: none;
    text-decoration: underline;
    text-underline-offset: 8px;
}

.category-link:hover img {
    scale: 1.2;
}

.card-link {
    text-decoration: none;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 50px !important;
}

.contact {

}

.contact .MuiSvgIcon-root {
    font-size: 2.5rem !important;
}

.body-link {
    text-decoration: none;
    font-weight: 500;
    padding: 4px 8px 4px 8px;
    color: var(--text-color) !important;
}

/* Extra Small */
@media only screen and (max-width: 600px) {
    .headerxl {
        font-weight: 400 !important;
        font-size: 3.563rem !important;
        line-height: 1 !important;
    }

    .header-large {
        font-weight: 500 !important;
        font-size: 5.063rem !important;
        line-height: 1 !important;
    }

    .header-1-2 {
        font-weight: 600 !important;
        font-size: 3.375rem !important;
        line-height: 1.2 !important;
    }
}

/* Small */
@media only screen and (min-width: 600px) and (max-width: 992px) {
    .headerxl {
        font-weight: 400 !important;
        font-size: 4.563rem !important;
        line-height: 1 !important;
    }

    .header-large {
        font-weight: 500 !important;
        font-size: 5.063rem !important;
        line-height: 1 !important;
    }

    .header-1-2 {
        font-weight: 600 !important;
        font-size: 3.375rem !important;
        line-height: 1.2 !important;
    }
}

.file-format-icon {
    max-height: 48px;
}
/* Medium */
@media only screen and (min-width: 992px) and (max-width: 1200px) {}

/* Large */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {}

/* Extra Large */
@media only screen and (min-width: 1400px) {}

/* Portrait */
@media only screen and (orientation: portrait) {}

/* Landscape */
@media only screen and (orientation: landscape) {}