.div_button  {
    background-color: lightgray;
}

.div_button button {
    margin: 35px;
    cursor: pointer;
    background-color: blue;
    color: white;
    padding: 10px 0px 10px 0px;
    width: 70px;
    border-radius: 10px
}

.div_button label {
    margin-left: 35px;
    font-size: 15px;
}

.div_button input {
    margin-left: 10px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    outline: 2px solid black;
}
